body {
  background-color: #eaf0f5;
  margin: 0;
  font-family: 'FSEmeric-Regular', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'FSEmeric-Regular';
  src: local('FSEmericWeb-Regular'), url(./resources/fonts/FSEmericWeb-Regular.woff) format('woff');
}

@font-face {
  font-family: 'FSEmeric-Medium';
  src: local('FSEmericWeb-Medium'), url(./resources/fonts/FSEmericWeb-Medium.woff) format('woff');
}

@font-face {
  font-family: 'FSEmeric-Bold';
  src: local('FSEmericWeb-Bold'), url(./resources/fonts/FSEmericWeb-Bold.woff) format('woff');
}
